import VueDataObject from 'o365.modules.VueDataObject.js';
import { createDataObject } from 'o365.modules.VueDataObject.js';


export default class ReportDataObject extends VueDataObject {

    constructor(options) {
        super(options);
        console.log(" from Report Data Object", this);
    }
}

const initReport = function (reportID) {
    console.log("initReport with ID:", reportID);
    //return new ReportDataObject();

}
export {initReport}